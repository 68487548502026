import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { StoreVecinoFormService } from '../../../services/store-vecino-form.service';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { VecinoContacto } from '../../../interfaces/vecinoContacto.interface';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { BuvService } from '../../../services/buv.service';
import { Vecino } from '../../../interfaces/vecino.interface';

@Component({
  selector: 'app-vecino-contacto-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    CommonModule,
  ],
  templateUrl: './vecino-contacto-form.component.html',
  styleUrl: './vecino-contacto-form.component.scss',
})
export class VecinoContactoFormComponent implements OnInit {
  @Input() requestFormSignal$: Observable<boolean> = new Observable();
  modoEdicion: boolean = false;

  tipoContacto: any[] = [
    { id: 1, description: 'Correo electronico' },
    { id: 2, description: 'Celular' },
    { id: 3, description: 'Telefono' },
    { id: 4, description: 'Tel. Laboral' },
  ];

  tipoContactoFiltered: any[] = this.tipoContacto;

  tiposPropiedad: any[] = [
    { id: 1, description: 'Es propio' },
    { id: 2, description: 'Es de terceros' },
  ];

  vecinoContactoForm: FormGroup = this._fb.group({});

  labelValorContacto: string = 'Correo electronico';

  contactos: VecinoContacto[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = ['tipoContacto', 'valor', 'accion'];

  vecino: Vecino | null = null;

  constructor(
    private _fb: FormBuilder,
    private _storeVecinoFormService: StoreVecinoFormService,
    private _buvService: BuvService
  ) {}

  ngOnInit(): void {
    this._formBuilder();
    this.requestFormSignal$.subscribe((e) => {
      if (e) {
        this._sendFormToService();
      }
    });
    this._buvService.vecinoAEditar$.subscribe((vecinoAEditar) => {
      this.vecino = vecinoAEditar;
      if (vecinoAEditar) {
        this.contactos = [];
        this._parseContactosToArray(vecinoAEditar);
        this._filtrarOpcionesTipoContacto();
        console.log(this.tipoContactoFiltered);
        this.dataSource = new MatTableDataSource(this.contactos);
      }
    });
  }

  private _filtrarOpcionesTipoContacto(): void {
    const idsContactos = this.contactos.map((c) => c.tipoContacto);
    this.tipoContactoFiltered = this.tipoContacto.filter(
      (t) => !idsContactos.includes(t.id.toString())
    );
  }

  private _parseContactosToArray(vecino: Vecino): void {
    if (vecino.correoElectronico) {
      this._addItemToContactos(1, vecino.correoElectronico);
    }
    if (vecino.celular) {
      this._addItemToContactos(2, vecino.celular);
    }
    if (vecino.telefono) {
      this._addItemToContactos(3, vecino.telefono);
    }
    if (vecino.telefonoLaboral) {
      this._addItemToContactos(4, vecino.telefonoLaboral);
    }
  }

  private _addItemToContactos(type: number, value: string): void {
    this.contactos.push({
      tipoContacto: type.toString(),
      contactoDescripcion: value,
    });
  }

  private _sendFormToService(): void {
    if (this.vecinoContactoForm.invalid) {
      this.vecinoContactoForm.markAllAsTouched();
      return;
    }
    this._storeVecinoFormService.vecinoContactoForm$.next(
      this.vecinoContactoForm
    );
  }

  private _formBuilder(): void {
    this.vecinoContactoForm = this._fb.group({
      tipoContacto: [],
      valorContacto: [],
    });
  }

  getTipoContacto(tipoContactoLabel: string): string {
    const tipoContactoId = Number(tipoContactoLabel);
    const tipoConacto = this.tipoContacto.find((e) => e.id == tipoContactoId);
    return tipoConacto.description;
  }

  guardarNuevoContacto(): void {
    if (!this.vecino) return;
    const { tipoContacto, valorContacto } = this.vecinoContactoForm.value;
    switch (tipoContacto) {
      case 1:
        this.vecino.correoElectronico = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
      case 2:
        this.vecino.celular = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
      case 3:
        this.vecino.telefono = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
      default:
        this.vecino.telefonoLaboral = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
    }
  }

  guardarEdicionContacto(): void {
    if (!this.vecino) return;
    this._filtrarOpcionesTipoContacto();
    const { tipoContacto, valorContacto } = this.vecinoContactoForm.value;
    switch (tipoContacto) {
      case 1:
        this.vecino.correoElectronico = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
      case 2:
        this.vecino.celular = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
      case 3:
        this.vecino.telefono = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
      default:
        this.vecino.telefonoLaboral = valorContacto;
        this.vecinoContactoForm.reset();
        this.modoEdicion = false;
        return this._buvService.vecinoAEditar$.next(this.vecino);
    }
  }

  editarContacto(vecinoContacto: VecinoContacto): void {
    this._filtrarOpcionesTipoContacto();

    this.modoEdicion = true;
    if (
      !this.tipoContactoFiltered.find(
        (t) => t.id === Number(vecinoContacto.tipoContacto)
      )
    ) {
      const tipoContactoAEditar = this.tipoContacto.find(
        (t) => t.id === Number(vecinoContacto.tipoContacto)
      );

      this.tipoContactoFiltered.push(tipoContactoAEditar);
    }
    this._llenarCamposContacto(vecinoContacto);
  }

  cancelarEdicion(): void {
    this.vecinoContactoForm.reset();
    this._filtrarOpcionesTipoContacto();
    this.modoEdicion = false;
  }

  private _llenarCamposContacto(vecinoContacto: VecinoContacto): void {
    this.vecinoContactoForm.patchValue({
      tipoContacto: Number(vecinoContacto.tipoContacto),
      valorContacto: vecinoContacto.contactoDescripcion,
    });
  }
}
