<div class="main-container pb-6">
  <div class="div-width-container flex flex-col items-center w-full">
    <div class="form-containers flex flex-col gap-4 w-3/4 pt-12">
      <h2 class="register-title text-left w-full">
        Servicio de alta manual de vecinos
      </h2>
      <app-vecino-persona-form></app-vecino-persona-form>
      <app-vecino-direccion-form></app-vecino-direccion-form>
      <app-vecino-contacto-form></app-vecino-contacto-form>
      <button
        mat-raised-button
        color="primary"
        class="save-button w-1/3 m-auto"
      >
        <span class="flex flex-row items-center justify-center gap-2">
          Guardar <mat-icon>save</mat-icon>
        </span>
      </button>
    </div>
  </div>
</div>
