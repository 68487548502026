import { Component, Input } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Observable } from 'rxjs';
import { StoreVecinoFormService } from '../../../services/store-vecino-form.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { BuvService } from '../../../services/buv.service';
import { Vecino } from '../../../interfaces/vecino.interface';
import { IFormVecinoDatosPersonales } from '../../../interfaces/formVecinoDatosPersonales.interface';

@Component({
  selector: 'app-vecino-persona-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
    MatDatepickerModule,
    MatDividerModule,
    MatListModule,
  ],
  providers: [provideNativeDateAdapter()],
  templateUrl: './vecino-persona-form.component.html',
  styleUrl: './vecino-persona-form.component.scss',
})
export class VecinoPersonaFormComponent {
  @Input() requestFormSignal$: Observable<boolean> = new Observable();

  tiposDni: any[] = [
    { id: 1, description: 'DNI' },
    { id: 2, description: 'Pasaporte' },
    { id: 3, description: 'Lib. Cívica' },
    { id: 4, description: 'Lib. Enrolamiento' },
    { id: 5, description: 'Cert. Precaria' },
  ];

  tiposSexo: any[] = [
    { id: 1, description: 'Masculino' },
    { id: 2, description: 'Femenino' },
    { id: 3, description: 'Otro' },
  ];

  vecinoPersonaForm: FormGroup = this._fb.group({});

  ultimaModificacion: string = '';
  nivelDeValidacion: number = -1;
  origenValidacion: string = '';

  constructor(
    private _fb: FormBuilder,
    private _storeVecinoForm: StoreVecinoFormService,
    private _buvService: BuvService
  ) {}

  ngOnInit(): void {
    this._formBuilder();
    this.requestFormSignal$.subscribe((e) => {
      if (e) {
        this._sendFormToService();
      }
    });
    this._buvService.vecinoAEditar$.subscribe((vecinoAEditar) => {
      if (vecinoAEditar) {
        this._fillFormWithValues(vecinoAEditar);
      }
    });
  }

  validarConRenaper(): void {}

  private _sendFormToService(): void {
    if (this.vecinoPersonaForm.invalid) {
      this.vecinoPersonaForm.markAllAsTouched();
      return;
    }
    this._storeVecinoForm.vecinoPersonaForm$.next(this.vecinoPersonaForm);
  }

  private _fillFormWithValues(vecinoAEditar: Vecino): void {
    const vecinoData: IFormVecinoDatosPersonales = {
      tipoDeDocumento: vecinoAEditar.tipoDeDocumento
        ? vecinoAEditar.tipoDeDocumento
        : '',
      numeroDocumento: vecinoAEditar.numeroDocumento
        ? vecinoAEditar.numeroDocumento
        : '',
      nombre: vecinoAEditar.nombre ? vecinoAEditar.nombre : '',
      apellido: vecinoAEditar.apellido ? vecinoAEditar.apellido : '',
      cuil: vecinoAEditar.cuil
        ? vecinoAEditar.cuil
        : vecinoAEditar.username
        ? vecinoAEditar.username
        : '',
      verificado: vecinoAEditar.verificado ? vecinoAEditar.verificado : '',
      nivelValidacion: vecinoAEditar.nivelValidacion
        ? vecinoAEditar.nivelValidacion
        : '',
      origenValidacion: vecinoAEditar.nivelValidacion
        ? vecinoAEditar.nivelValidacion
        : '',
      fechaModificacion: vecinoAEditar.fechaModificacion
        ? vecinoAEditar.fechaModificacion
        : '',
      fechaDeNacimiento: vecinoAEditar.fechaDeNacimiento
        ? vecinoAEditar.fechaDeNacimiento
        : '',
      sexo: vecinoAEditar.sexo ? vecinoAEditar.sexo : '',
    };
    this.vecinoPersonaForm.patchValue({
      tipoDocumento: this._getTipoDocumentoId(vecinoData.tipoDeDocumento),
      nroDocumento: vecinoData.numeroDocumento,
      nombre: vecinoData.nombre,
      apellido: vecinoData.apellido,
      cuil: vecinoData.cuil,
      fecNacimiento: vecinoData.fechaDeNacimiento,
      sexo: this._getSexoId(vecinoData.sexo),
    });
    this.ultimaModificacion = vecinoAEditar.fechaModificacion
      ? vecinoAEditar.fechaModificacion
      : '';
    this.nivelDeValidacion = vecinoAEditar.nivelValidacion
      ? Number(vecinoAEditar.nivelValidacion)
      : 0;
    this.origenValidacion = vecinoAEditar.origenValidacion
      ? vecinoAEditar.origenValidacion
      : 'Sin validacion';
  }

  private _formBuilder(): void {
    this.vecinoPersonaForm = this._fb.group({
      tipoDocumento: [''],
      nroDocumento: [''],

      nombre: [''],
      apellido: [''],
      cuil: [''],

      fecNacimiento: [''],
      sexo: [''],
    });
  }

  private _getSexoId(sexoLabel: string): number {
    const sexoLower = sexoLabel.toLowerCase();

    switch (sexoLower) {
      case 'm':
      case 'masculino':
        return 1;
      case 'f':
      case 'femenino':
        return 2;
      default:
        return 3; // O lanzar una excepción si no es válido
    }
  }

  private _getTipoDocumentoId(tipoDocumentoLabel: string): number | null {
    const tipoDniLower = tipoDocumentoLabel.toLowerCase();
    switch (tipoDniLower) {
      case 'dni':
        return 1;
      default:
        return null; // O lanzar una excepción si no es válido
    }
  }
}
