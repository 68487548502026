import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { StoreVecinoFormService } from '../../../services/store-vecino-form.service';
import { Observable } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { IFormVecinoDomicilios } from '../../../interfaces/formVecinoDomicilios.interface';
import { Domicilio } from '../../../interfaces/domicilio.interface';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { BuvService } from '../../../services/buv.service';
import { MatButtonModule } from '@angular/material/button';
import { Vecino } from '../../../interfaces/vecino.interface';

@Component({
  selector: 'app-vecino-direccion-form',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatTableModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './vecino-direccion-form.component.html',
  styleUrl: './vecino-direccion-form.component.scss',
})
export class VecinoDireccionFormComponent implements OnInit {
  @Input() requestFormSignal$: Observable<boolean> = new Observable();

  tiposDomicilio: any[] = [
    { id: 1, description: 'Registrado' },
    { id: 2, description: 'Real' },
    { id: 3, description: 'Legal' },
    { id: 4, description: 'Laboral' },
  ];

  vecinoDireccionForm: FormGroup = this._fb.group({});

  modoEdicion: boolean = false;
  domicilioAEditarId: string | null = null;

  domicilios: Domicilio[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  displayedColumns: string[] = [
    'fechaUpdate',
    'tipoDomicilio',
    'partido',
    'localidad',
    'calle',
    'numero',
    'deptoPiso',
    'codPostal',
    'accion',
  ];

  vecino: Vecino | null = null;

  constructor(
    private _fb: FormBuilder,
    private _storeVecinoForm: StoreVecinoFormService,
    private _buvService: BuvService
  ) {}

  ngOnInit(): void {
    this._formBuilder();
    this.requestFormSignal$.subscribe((e) => {
      if (e) {
        this._sendFormToService();
      }
    });
    this._buvService.vecinoAEditar$.subscribe((vecinoAEditar) => {
      this.vecino = vecinoAEditar;
      if (vecinoAEditar) {
        this.domicilios = vecinoAEditar.domicilios;
        this.dataSource = new MatTableDataSource(this.domicilios);
      }
    });
  }

  getTipoDomicilioId(domicilioLabel: string): string {
    const tipoDomicilio = Number(domicilioLabel);
    const domicilio = this.tiposDomicilio.find((e) => e.id == tipoDomicilio);
    return domicilio.description;
  }

  editarVecino(domicilioId: string): void {
    this.modoEdicion = true;
    this.domicilioAEditarId = domicilioId;
    this._llenarCamposDomicilio(domicilioId);
  }

  cancelarEdicion(): void {
    this.vecinoDireccionForm.reset();
    this.domicilioAEditarId = null;
    this.modoEdicion = false;
  }

  guardarNuevoDomicilio(): void {
    if (this.vecinoDireccionForm.invalid) {
      this.vecinoDireccionForm.markAllAsTouched();
      return;
    }
    if (!this.vecino) return;
    const formValues = this.vecinoDireccionForm.value;
    const direccionNueva: Domicilio = {
      idVecino: this.vecino.id,
      tipoDomicilio: Number(formValues.tipoDomicilio),
      partido: formValues.partido,
      localidad: formValues.localidad,
      cp: formValues.codPostal,
      calle: formValues.calle,
      numero: formValues.altura,
      torre: formValues.torre,
      piso: formValues.piso,
      depto: formValues.depto,
      updated_at: new Date().toString(),
    };
    this.vecino.domicilios.push(direccionNueva);
    this._buvService.vecinoAEditar$.next(this.vecino);
    this.vecinoDireccionForm.reset();
  }

  guardarEdicionDomicilio(): void {
    if (this.vecinoDireccionForm.invalid) {
      this.vecinoDireccionForm.markAllAsTouched();
      return;
    }
    if (!this.vecino) return;
    const formValues = this.vecinoDireccionForm.value;
    const direccionEditada: Domicilio = {
      tipoDomicilio: Number(formValues.tipoDomicilio),
      partido: formValues.partido,
      localidad: formValues.localidad,
      cp: formValues.codPostal,
      calle: formValues.calle,
      numero: formValues.altura,
      torre: formValues.torre,
      piso: formValues.piso,
      depto: formValues.depto,
    };
    this.vecino.domicilios = this.vecino.domicilios.map((domicilio) => {
      if (domicilio.id === this.domicilioAEditarId) {
        return {
          ...domicilio, // Mantener propiedades existentes
          ...direccionEditada, // Añadir propiedades editadas
        };
      } else {
        return domicilio;
      }
    });
    this._buvService.vecinoAEditar$.next(this.vecino);
    this.vecinoDireccionForm.reset();
    this.modoEdicion = false;
    this.domicilioAEditarId = null;
  }

  private _llenarCamposDomicilio(domicilioId: string): void {
    const domicilio = this.domicilios.find(
      (domicilio) => domicilio.id === domicilioId
    );
    if (domicilio) {
      this.vecinoDireccionForm.patchValue({
        tipoDomicilio: Number(domicilio.tipoDomicilio),
        partido: domicilio.partido,
        localidad: domicilio.localidad,
        codPostal: domicilio.cp,

        calle: domicilio.calle,
        altura: domicilio.numero,
        torre: domicilio.torre,
        piso: domicilio.piso,
        depto: domicilio.depto,
      });
    }
  }

  private _sendFormToService(): void {
    if (this.vecinoDireccionForm.invalid) {
      this.vecinoDireccionForm.markAllAsTouched();
      return;
    }
    this._storeVecinoForm.vecinoDireccionForm$.next(this.vecinoDireccionForm);
  }

  private _formBuilder(): void {
    this.vecinoDireccionForm = this._fb.group({
      tipoDomicilio: ['', [Validators.required]],
      partido: ['', [Validators.required]],
      localidad: ['', [Validators.required]],
      codPostal: ['', [Validators.required]],

      calle: ['', [Validators.required]],
      altura: ['', [Validators.required]],
      torre: [''],
      piso: [''],
      depto: [''],
    });
  }
}
