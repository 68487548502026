<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header>
      <mat-card-title class="form-title"> Contactos registrados</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="table-container pt-3">
        @if (contactos.length>0) {
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0 pt-5"
        >
          <ng-container matColumnDef="tipoContacto">
            <th mat-header-cell *matHeaderCellDef>Tipo Contacto</th>
            <td mat-cell *matCellDef="let element">
              {{ getTipoContacto(element.tipoContacto) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="valor">
            <th mat-header-cell *matHeaderCellDef>Contacto</th>
            <td mat-cell *matCellDef="let element">
              {{ element.contactoDescripcion }}
            </td>
          </ng-container>

          <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef>Acción</th>
            <td mat-cell *matCellDef="let element">
              <div class="button-container flex flex-nowrap">
                <button
                  mat-icon-button
                  matTooltip="Edit Job"
                  (click)="editarContacto(element)"
                >
                  <mat-icon class="edit-button">edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit Job">
                  <mat-icon class="trash-button">delete</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        }@else {
        <mat-card-subtitle class="pt-2 text-center">
          No hay contactos registrados</mat-card-subtitle
        >
        }
      </div>

      <mat-card-title class="form-title">Registrar contacto</mat-card-title>
      <div class="form-container text-left pt-8">
        <form [formGroup]="vecinoContactoForm" class="flex flex-wrap gap-6">
          <div class="flex w-full gap-6 items-center">
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Tipo de contacto*</mat-label>
              <mat-select formControlName="tipoContacto">
                @for (tipoContacto of tipoContactoFiltered; track
                tipoContacto.id) {
                <mat-option [value]="tipoContacto.id">{{
                  tipoContacto.description
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <!-- correo electronico -->
            <mat-form-field appearance="outline" class="w-2/3">
              <mat-label>{{ labelValorContacto }}*</mat-label>
              <input
                matInput
                placeholder="Jon"
                formControlName="valorContacto"
              />
            </mat-form-field>
          </div>
          <div class="flex flex-row items-center justify-between w-full">
            <span class="flex-grow">
              Importante: Los datos señalados con * son obligatorios
            </span>

            @if(modoEdicion) {
            <div class="buttons-container flex flex-row gap-3 basis-1/3">
              <button
                mat-raised-button
                class="save-button m-auto flex-grow"
                (click)="guardarEdicionContacto()"
              >
                <span class="flex flex-row items-center justify-center gap-2">
                  Modificar
                </span>
              </button>
              <button
                mat-raised-button
                class="save-button-danger m-auto basis-2/5"
                (click)="cancelarEdicion()"
              >
                <span class="flex flex-row items-center justify-center gap-2">
                  Cancelar
                </span>
              </button>
            </div>
            }@else {
            <button
              mat-raised-button
              color="primary"
              class="save-button m-auto basis-1/4"
              (click)="guardarNuevoContacto()"
            >
              <span class="flex flex-row items-center justify-center gap-2">
                Agregar nuevo contacto
              </span>
            </button>
            }
          </div>
        </form>
      </div>
    </mat-card-content>
  </div>
</mat-card>
