import { VecinoPersonaFormComponent } from './../../../registrar-vecino-main/components/vecino-persona-form/vecino-persona-form.component';
import { Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { VECINOSEJEMPLO } from '../../const/ejemploVecinos';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { Vecino } from '../../../interfaces/vecino.interface';

@Component({
  selector: 'app-buscar-vecino-resultados-table',
  standalone: true,
  imports: [
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatTableModule,
    CommonModule,
    RouterModule,
  ],
  templateUrl: './buscar-vecino-resultados-table.component.html',
  styleUrl: './buscar-vecino-resultados-table.component.scss',
})
export class BuscarVecinoResultadosTableComponent implements OnInit {
  @Input() resultadoVecinos$: Observable<Vecino[]> = new Observable<Vecino[]>();
  @Input() showEmptyResultSimpleRequest$: Observable<boolean> =
    new Observable<boolean>();

  displayedColumns: string[] = [
    'fechaCreate',
    'fechaUpdate',
    'dni',
    'nombre',
    'apellido',
    'renaper',
    'buv',
    'accion',
  ];

  dataSource: MatTableDataSource<any> = new MatTableDataSource();
  showEmptyResultSimpleRequest: boolean = false;

  constructor(private _router: Router) {}

  ngOnInit(): void {
    this.showEmptyResultSimpleRequest$.subscribe(
      (show) => (this.showEmptyResultSimpleRequest = show)
    );
    this.resultadoVecinos$.subscribe((vecinos) => {
      if (vecinos.length > 0) {
        this.dataSource = new MatTableDataSource(vecinos);
      }
    });
  }

  crearNuevoVecino(): void {
    this._router.navigate(['registrar']);
  }
}
