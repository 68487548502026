<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header>
      <mat-card-title class="form-title">
        Domicilios registrados</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <div class="table-container pt-5">
        @if (domicilios.length>0) {
        <table
          mat-table
          [dataSource]="dataSource"
          class="mat-elevation-z0 pt-5"
        >
          <ng-container matColumnDef="fechaUpdate">
            <th mat-header-cell *matHeaderCellDef>Última edición</th>
            <td mat-cell *matCellDef="let element">
              {{ element.updated_at | date : "dd/MM/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="tipoDomicilio">
            <th mat-header-cell *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
              {{ getTipoDomicilioId(element.tipoDomicilio) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="localidad">
            <th mat-header-cell *matHeaderCellDef>Localidad</th>
            <td mat-cell *matCellDef="let element">{{ element.localidad }}</td>
          </ng-container>

          <ng-container matColumnDef="partido">
            <th mat-header-cell *matHeaderCellDef>Partido</th>
            <td mat-cell *matCellDef="let element">
              {{ element.partido }}
            </td>
          </ng-container>

          <ng-container matColumnDef="calle">
            <th mat-header-cell *matHeaderCellDef>Calle</th>
            <td mat-cell *matCellDef="let element">
              {{ element.calle }}
            </td>
          </ng-container>

          <ng-container matColumnDef="numero">
            <th mat-header-cell *matHeaderCellDef>Altura</th>
            <td mat-cell *matCellDef="let element">
              {{ element.numero }}
            </td>
          </ng-container>

          <ng-container matColumnDef="deptoPiso">
            <th mat-header-cell *matHeaderCellDef>Depto/Piso</th>
            <td mat-cell *matCellDef="let element">
              {{ element.depto + "/" + element.piso }}
            </td>
          </ng-container>

          <ng-container matColumnDef="codPostal">
            <th mat-header-cell *matHeaderCellDef>Cod. Postal</th>
            <td mat-cell *matCellDef="let element">
              {{ element.cp }}
            </td>
          </ng-container>

          <ng-container matColumnDef="accion">
            <th mat-header-cell *matHeaderCellDef>Editar | Eliminar</th>
            <td mat-cell *matCellDef="let element">
              <div class="button-container flex flex-nowrap">
                <button
                  mat-icon-button
                  matTooltip="Edit Job"
                  (click)="editarVecino(element.id)"
                >
                  <mat-icon class="edit-button">edit</mat-icon>
                </button>
                <button mat-icon-button matTooltip="Edit Job">
                  <mat-icon class="edit-button">remove</mat-icon>
                </button>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        }@else {
        <mat-card-subtitle class="pt-2 text-center">
          No hay domicilios registrados</mat-card-subtitle
        >
        }
      </div>

      <mat-card-title class="form-title pt-8">
        Agregar domicilio</mat-card-title
      >

      <div class="form-container text-left pt-8">
        <form [formGroup]="vecinoDireccionForm" class="flex flex-wrap gap-6">
          <div class="flex w-full gap-6">
            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Tipo de domicilio</mat-label>
              <mat-select formControlName="tipoDomicilio">
                @for (tipoDomicilio of tiposDomicilio; track tipoDomicilio.id) {
                <mat-option [value]="tipoDomicilio.id">{{
                  tipoDomicilio.description
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Partido</mat-label>
              <input
                matInput
                placeholder="Vicente López"
                formControlName="partido"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Localidad</mat-label>
              <input
                matInput
                placeholder="La Plata"
                formControlName="localidad"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="basis-1/5">
              <mat-label>Código Postal</mat-label>
              <input matInput placeholder="1900" formControlName="codPostal" />
            </mat-form-field>
          </div>

          <div class="flex w-full gap-6">
            <mat-form-field appearance="outline" class="basis-2/5">
              <mat-label>Calle</mat-label>
              <input
                matInput
                placeholder="Av. Arenales"
                formControlName="calle"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Altura</mat-label>
              <input
                matInput
                placeholder="Av. Arenales"
                formControlName="altura"
              />
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Torre</mat-label>
              <input matInput placeholder="C" formControlName="torre" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Piso</mat-label>
              <input matInput placeholder="2" formControlName="piso" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="flex-grow">
              <mat-label>Depto</mat-label>
              <input matInput placeholder="1353" formControlName="depto" />
            </mat-form-field>
          </div>
        </form>
      </div>
      <div class="flex flex-row items-center justify-between">
        <span class="flex-grow">
          Importante: Los datos señalados con * son obligatorios
        </span>

        @if(modoEdicion) {
        <div class="buttons-container flex flex-row gap-3 basis-1/3">
          <button
            mat-raised-button
            class="save-button m-auto flex-grow"
            (click)="guardarEdicionDomicilio()"
          >
            <span class="flex flex-row items-center justify-center gap-2">
              Modificar
            </span>
          </button>
          <button
            mat-raised-button
            class="save-button-danger m-auto basis-2/5"
            (click)="cancelarEdicion()"
          >
            <span class="flex flex-row items-center justify-center gap-2">
              Cancelar
            </span>
          </button>
        </div>
        }@else {
        <button
          mat-raised-button
          color="primary"
          class="save-button m-auto basis-1/4"
          (click)="guardarNuevoDomicilio()"
        >
          <span class="flex flex-row items-center justify-center gap-2">
            Agregar nueva dirección
          </span>
        </button>
        }
      </div>
    </mat-card-content>
  </div>
</mat-card>
