<mat-card appearance="outlined" class="mb-5">
  <div class="card-container m-5">
    <mat-card-header>
      <mat-card-title class="form-title text-base"> Resultados </mat-card-title>
    </mat-card-header>
    <mat-card-content class="mt-5">
      @if(showEmptyResultSimpleRequest){
      <div
        class="no-results-container flex flex-row justify-between items-center"
      >
        <div class="text-container">
          <p class="font-bold">
            No existen resultados en la BUV que coincidan con tu búsqueda
            actual.
          </p>
          <p>
            Podés ajustar los datos ingresados o realizar la carga manual de un
            nuevo vecino.
          </p>
        </div>
        <div class="button-container">
          <button
            mat-raised-button
            color="primary"
            class="save-button flex items-center justify-center mb-2"
            (click)="crearNuevoVecino()"
          >
            Nuevo vecino
          </button>
        </div>
      </div>
      }@else {

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z0 pt-5">
        <ng-container matColumnDef="dni">
          <th mat-header-cell *matHeaderCellDef>DNI</th>
          <td mat-cell *matCellDef="let element">
            {{ element.numeroDocumento }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef>Nombre</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nombre ? element.nombre : "Sin nombre" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="apellido">
          <th mat-header-cell *matHeaderCellDef>Apellido</th>
          <td mat-cell *matCellDef="let element">{{ element.apellido }}</td>
        </ng-container>

        <ng-container matColumnDef="fechaUpdate">
          <th mat-header-cell *matHeaderCellDef>Ultima edición</th>
          <td mat-cell *matCellDef="let element">
            {{ element.updated_at | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="fechaCreate">
          <th mat-header-cell *matHeaderCellDef>Alta</th>
          <td mat-cell *matCellDef="let element">
            {{ element.created_at | date : "dd/MM/yyyy" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="renaper">
          <th mat-header-cell *matHeaderCellDef>RENAPER</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex flex-row items-center gap-1">
              <mat-icon
                [ngClass]="
                  element.origenValidacion === 'RENAPER'
                    ? 'validated'
                    : 'not-validated'
                "
                >{{
                  element.origenValidacion === "RENAPER"
                    ? "check_circle"
                    : "cancel"
                }}</mat-icon
              ><span class="text-nowrap">{{
                element.origenValidacion === "RENAPER"
                  ? "validado"
                  : "no validado"
              }}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="buv">
          <th mat-header-cell *matHeaderCellDef>BUV</th>
          <td mat-cell *matCellDef="let element" class="text-nowrap">
            {{
              "Nivel " + (element.nivelValidacion ? element.nivelValidacion : 0)
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="accion">
          <th mat-header-cell *matHeaderCellDef>Ver/Editar</th>
          <td mat-cell *matCellDef="let element">
            <a [routerLink]="['/edicion/' + element.id]" class="underline"
              >Acceder</a
            >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      }
    </mat-card-content>
  </div>
</mat-card>
