import { Component, OnInit } from '@angular/core';
import { VecinoPersonaFormComponent } from './components/vecino-persona-form/vecino-persona-form.component';
import { VecinoDireccionFormComponent } from './components/vecino-direccion-form/vecino-direccion-form.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { VecinoContactoFormComponent } from './components/vecino-contacto-form/vecino-contacto-form.component';
import { ActivatedRoute } from '@angular/router';
import { BuvService } from '../services/buv.service';
import { GenericFilter } from '../interfaces/genericFilter.interface';
import { BehaviorSubject } from 'rxjs';
import { Vecino } from '../interfaces/vecino.interface';

@Component({
  selector: 'app-registrar-vecino-main',
  standalone: true,
  imports: [
    VecinoPersonaFormComponent,
    VecinoDireccionFormComponent,
    VecinoContactoFormComponent,
    MatButtonModule,
    MatIconModule,
  ],
  templateUrl: './registrar-vecino-main.component.html',
  styleUrl: './registrar-vecino-main.component.scss',
})
export class RegistrarVecinoMainComponent implements OnInit {
  private _vecinoId: number | null = null;

  constructor(private _buvService: BuvService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this._vecinoId = params['id'];
      if (this._vecinoId) {
        let filter: GenericFilter = {
          id: this._vecinoId.toString(),
        };
        this._buvService.buvGetVecinos(filter).subscribe((vecino) => {
          //manejar el error de si entro y no hay vecino que rediriga hacia atras de nuevo
          this._buvService.vecinoAEditar$.next(vecino[0]);
        });
      }
    });
  }
}
