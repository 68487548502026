import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BuscarVecinoFormService } from '../../../services/buscar-vecino-form.service';
import { LOCALIDADES } from '../../const/localidades';

@Component({
  selector: 'app-buscar-vecino-form-avanzado',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './buscar-vecino-form-avanzado.component.html',
  styleUrl: './buscar-vecino-form-avanzado.component.scss',
})
export class BuscarVecinoFormAvanzadoComponent implements OnInit {
  buscarVecinoFormSimple: FormGroup = this._fb.group({});

  @Output() submit$: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  tiposSexo: any[] = [
    { id: 1, description: 'F - Femenino' },
    { id: 2, description: 'M - Masculino' },
    { id: 3, description: 'Otro' },
  ];

  localidades: any[] = [];

  constructor(
    private _fb: FormBuilder,
    private _buscarVecinoFormService: BuscarVecinoFormService
  ) {}

  ngOnInit() {
    this._formBuilder();
    this.localidades = LOCALIDADES;
  }

  cambiarBusqueda(): void {
    this._buscarVecinoFormService.formSeleccionado$.next(0);
  }

  submit(): void {
    if (this.buscarVecinoFormSimple.invalid) {
      this.buscarVecinoFormSimple.markAllAsTouched();
      return;
    }
    this.submit$.emit(this.buscarVecinoFormSimple);
  }

  private _formBuilder(): void {
    this.buscarVecinoFormSimple = this._fb.group({
      nombre: [''],
      apellido: [''],
      sexoId: [''],
      localidadId: [''],
      calle: [''],
      altura: [''],
      codPostal: [''],
      email: [''],
      telefono: [''],
    });
  }
}
