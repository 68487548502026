<mat-card appearance="outlined">
  <div class="card-container m-5">
    <mat-card-header>
      <mat-card-title class="form-title">
        Información personal del vecino</mat-card-title
      >
    </mat-card-header>
    <mat-card-content>
      <div class="form-container text-left pt-8">
        <form [formGroup]="vecinoPersonaForm" class="flex flex-wrap gap-6">
          <div class="flex w-full gap-6">
            <!-- tipo documento -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Tipo de documento*</mat-label>
              <mat-select formControlName="tipoDocumento">
                @for (tipoDni of tiposDni; track tipoDni.id) {
                <mat-option [value]="tipoDni.id">{{
                  tipoDni.description
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <!-- nro documento -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Numero Documento*</mat-label>
              <input
                matInput
                placeholder="12121212"
                formControlName="nroDocumento"
              />
            </mat-form-field>

            <div class="button-container flex flex-col items-center w-1/3">
              <button
                mat-raised-button
                color="primary"
                class="save-button flex items-center justify-center mb-2"
                (click)="validarConRenaper()"
                [disabled]="nivelDeValidacion >= 1"
              >
                Validar con RENAPER
              </button>
            </div>
          </div>

          <div class="flex w-full gap-6">
            <!-- nombre -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Nombre*</mat-label>
              <input matInput placeholder="Jon" formControlName="nombre" />
            </mat-form-field>

            <!-- apellido -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Apellido*</mat-label>
              <input matInput placeholder="Doe" formControlName="apellido" />
            </mat-form-field>

            <!-- CUIL -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>CUIL*</mat-label>
              <input
                matInput
                placeholder="00-00000000-0"
                formControlName="cuil"
              />
            </mat-form-field>
          </div>

          <div class="w-full -mt-5">
            <mat-list class="w-full">
              <div
                class="flex flex-row justify-between items-center w-full px-3"
              >
                <p>Ult. modificación: {{ ultimaModificacion }}</p>
                <div class="flex flex-row items-center gap-5">
                  <p class="text-nowrap">
                    Origen de Validación: {{ origenValidacion }}
                  </p>
                  <p class="text-nowrap">Nivel {{ nivelDeValidacion }}</p>
                </div>
              </div>
              <mat-divider></mat-divider>
            </mat-list>
          </div>

          <div class="flex w-full gap-6">
            <!-- Fecha de Nacimiento -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Fecha de Nacimiento*</mat-label>
              <input
                matInput
                [matDatepicker]="picker"
                formControlName="fecNacimiento"
              />
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <!-- Sexo -->
            <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Sexo*</mat-label>
              <mat-select formControlName="sexo">
                @for (tipoSexo of tiposSexo; track tipoSexo.id) {
                <mat-option [value]="tipoSexo.id">{{
                  tipoSexo.description
                }}</mat-option>
                }
              </mat-select>
            </mat-form-field>

            <div class="w-1/3"></div>
            <!-- <mat-form-field appearance="outline" class="w-1/3">
              <mat-label>Fecha de Nacimiento*</mat-label>
              <input matInput formControlName="fecNacimiento" />
            </mat-form-field> -->
          </div>
        </form>
      </div>
    </mat-card-content>
  </div>
</mat-card>
